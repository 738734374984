import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='Sobre o Admina'
            subheader='O admina é grupo de apoio na carreira de Produto e UX, sem objetivo financeiro e totalmente sem custo.'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img image={image} />
            <Button sx={styles.button}>Contact Me</Button>
          </Box>

          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title=''>


                <Text variant='p'>
                  <strong></strong>
                  Temos a <strong>missão</strong> de apoiar pessoas que sejam parte de  grupos de <strong>desvantagem social</strong> e que, por isso, enfrentam dificuldades para <strong>migrar</strong> ou para se <strong>desenvolver</strong> profissionalmente na área.
                </Text>
                <Text variant='p'>
                  <ul>
                    <li>Uma rede de apoio engajada, em que as pessoas se conhecem e identificam-se umas com as outras;</li>
                    <li>Aulões periódicos abertos somente a membros;</li>
                    <li>Mentoria individual;</li>
                    <li>Revisão de currículos, Linkedin e portfolio e apoio durante processos seletivos;</li>
                    <li>Mesas de bate papo, depoimentos e debates sobre migração e carreira;</li>
                    <li>Compartilhamento de um repositório de conteúdo;</li>
                    <li>Colaboração e co-criação de conteúdo;</li>
                  </ul>
                </Text>
                <Text variant='p'>
                    Para se cadastrar no admina, <a href="https://forms.gle/hu3GuPgwJRPUT8E88" >clique aqui</a>.
                </Text>

              </Section>
            </Box>

          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
